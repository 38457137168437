`
<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height" v-if="!channelPartner">
              <b-col cols="12">
                <tab-position :tabs="tabs"  :name="'Channel Partner'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/channelpartner`"
                title="Channel Partner"
                :columns="columns"
                :routerpath="'/master/crm/addchannelpartner'"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :newPage="true"
                :viewFields="viewFields"
                :canView="canView"
              >
                <template #action="{ row, getGridData }">
                  <b-badge
                    :id="'download' + row.id"
                    v-if="row.registration_doc"
                    @click="hanldeDownload(row)"
                    variant="success"
                    class="mr-50 cursor-pointer"
                  >
                    <b-spinner v-if="row.downloading" small variant="light" />

                    <feather-icon v-else icon="DownloadIcon" />
                  </b-badge>

                  <b-tooltip
                    v-if="row.registration_doc"
                    :id="'download' + row.id"
                    :target="'download' + row.id"
                    triggers="hover"
                  >
                    Download registration document
                  </b-tooltip>
                  <approve-reject
                    title="Channel Partner"
                    :row="row"
                    :getGridData="getGridData"
                    :disableButton="true"
                  />
                </template>
                <template #customField="{ props }">
                  <span v-if="props.column.field == 'is_active'">
                    <b-badge
                      :variant="props.row.is_active ? 'light-success' : 'light-danger'"
                    >
                      {{ props.row.is_active ? "Enabled" : "Disabled" }}
                    </b-badge>
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import TabPosition from "../../../../components/TabView.vue";
import { BRow, BCol, BBadge, BTooltip } from "bootstrap-vue";
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import approveReject from "@/components/approveReject.vue";

const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  props: ["channelPartner"],
  components: {
    GoodTableColumnSearch,
    approveReject,
    BRow,
    BCol,
    TabPosition,
    BTooltip,
    BBadge,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      canView: "",
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${
          this.$route.params.id ? "Edit Channel Partner" : "Add Channel Partner"
        }`,
        submitRouterPath: `${
          this.channelPartner ? "/channelpartner" : "/master/crm/Channelpartner"
        }`,
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getusersbyid`,
        url: `${baseApi}/channelpartner`,
        inputFields: [
          {
            type: "password",
            name: "password",
            value: "",
            placeholder: "Enter password",
            required: true,
          },
        ],
      },
      viewFields: [
        {
          label: "Profile Image",
          field: "profile_image",
          type: "image",
          class: "col-md-6",
        },
        {
          label: "Designation",
          field: "designation_role",
          type: "text",
          class: "col-md-6",
        },
        {
          label: "Surname",
          field: "surname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Name",
          field: "name",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Father Name",
          field: "fathername",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Email",
          field: "email",
          type: "email",
          class: "col-md-6",
        },
        {
          label: "Mobile",
          field: "mobile",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Gender",
          field: "gender",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Birth Date",
          field: "birthdate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Username",
          field: "username",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Joining Date",
          field: "joiningdate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Marital Status",
          field: "maritalstatus",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Anniversary Date",
          field: "anniversarydate",
          type: "anniversarydate",
          class: "col-md-4",
        },
        {
          label: "Current Address",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Address Line 1",
          field: "curaddressline1",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address Line 2",
          field: "curaddressline2",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pincode",
          field: "curpincode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "City",
          field: "curcity",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Area",
          field: "curarea",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Taluka",
          field: "curtaluka",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "state",
          field: "curstate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Permanent Address",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Address Line 1",
          field: "peraddressline1",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address Line 2",
          field: "peraddressline2",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pincode",
          field: "perpincode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "City",
          field: "percity",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Area",
          field: "perarea",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Taluka",
          field: "pertaluka",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "state",
          field: "perstate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Commission Type",
          field: "commissiontype",
          type: "dropdown",
          responseValue: "type",
          class: "col-md-4",
        },
        {
          label: "Parent",
          field: "parent",
          type: "dropdown",
          responseValue: "fullname",
          class: "col-md-4",
        },
        {
          label: "Bank Details",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Pan No",
          field: "panno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Aadhar No",
          field: "aadharcardno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Bank",
          field: "bankname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Branch",
          field: "bankbranch",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Account No",
          field: "bankaccno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "IFSC Code",
          field: "ifsccode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Aadhar Card Front Side",
          field: "aadharfrontside",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Aadhar Card Back Side",
          field: "aadharbackside",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Pan Card",
          field: "pancard",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Cancel Cheque",
          field: "cancelcheque",
          type: "attachment",
          class: "col-md-4",
        },
      ],
      columns: [
        {
          label: "Image",
          field: "profile_image",
          type: "image",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "User Name",
          field: "username",
          filterOptions: {
            enabled: true,
            placeholder: "Search User name",
          },
        },
        {
          label: "Name",
          field: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },
        {
          label: "Parent Name",
          field: "parent",
          url: "getUsers",
          responseValue: "fullname",
          type: "staticdropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search parent_name",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Joining Date",
          field: "joiningdate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        // {
        //   label: 'Address',
        //   field: 'address',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Address'
        //   }
        // },
        // {
        //   label: 'Area',
        //   field: 'area',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Area'
        //   }
        // },
        {
          label: "Status",
          field: "is_active",
          filterOptions: {
            enabled: true,
            placeholder: "Search City",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url: "getUsers",
          responseValue: "fullname",
          type: "staticdropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          url: "getUsers",
          responseValue: "fullname",
          type: "staticdropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.formOptions.columns.splice(2, 1);
      userData.map((item) => {
        if (item.modulename == "Channelpartner") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          this.canView = item.view;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  beforeMount() {
    // this.getDropdowns()
  },

  methods: {
    async hanldeDownload(row) {
      let url = row.registration_doc.includes("https")
        ? row.registration_doc
        : row.registration_doc.replace("http", "https");
      const fileURL = url;
      const fURL = document.createElement("a");
      fURL.href = fileURL;
      fURL.setAttribute("download", `registration_form_${row.fullname}`);
      document.body.appendChild(fURL);
      fURL.click();
      document.body.removeChild(fURL);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
